import React from "react"
import { Link, graphql } from "gatsby"
import Bio from "../components/about/bio"
import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import Animation from "../components/shared/animation"

const Articles = ({ className, data, location }) => {
  // const siteTitle = data.site.siteMetadata.title
  // const posts = data.allMarkdownRemark.edges

  // TODO: currently is reviews page content / need to think of index content - blue sky - recent post carousel
  return (
    <Layout>
      //
      <div>hello monkey</div>
    </Layout>
  )
}

const StyledInnerWrapper = styled.div`
  margin-top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

// const StyledIndex = styled(Index)`
//   overflow: hidden;
//   display: flex;
//   flex-direction: column;
//   background-repeat: no-repeat;
//   border-radius: 0.25rem;
//   width: 100%;
//   min-height: 60vh;
//   /* You should set a background-size as the default value is "cover"! */
//   background-size: fit;
//   /* So we won't have the default "lightgray" background-color. */
//   background-color: transparent;
// `

export default Articles

export const query = graphql`
  query {
    imageOne: file(relativePath: { eq: "clouds.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
